<template>
    <v-tooltip left>
        <template v-slot:activator="{ on }">
            <v-btn
                fab
                dark
                v-on="on"
                v-if="checkPermissions()"
                small
                @click.prevent="openModal()"
                class="buttons--add margin--1 ml-4"
            >
                <v-icon small>mdi-email-fast</v-icon>
            </v-btn>
        </template>
        <span>{{ $t('service-info:sendMail') }}</span>
    </v-tooltip>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters([
            'getServiceInfosDetails',
            'getProfileDetails',
            'getServiceInfosRegistry',
        ]),
    },
    methods: {
        openModal() {
            this.$emit('openConfirmModal')
        },
        checkPermissions() {
            if (
                this.getProfileDetails.isAdmin ||
                this.getServiceInfosRegistry.currentMenager.mail ==
                    this.getProfileDetails.mail ||
                this.getServiceInfosDetails.createdBy.mail ==
                    this.getProfileDetails.mail
            ) {
                return true
            }
            return false
        },
    }
}
</script>
