<template>
    <v-container>
        <v-row class="details__card">
            <v-col cols="3" xl="3" lg="3" md="5" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="12" class="d-flex flex-column">
                        <p
                            class="font-weight-bold d-flex flex-column headline pa-0 ma-0 mb-5"
                        >
                            <span
                                ><b>{{ `${$t('service-info:title')}` }}: </b>
                                <span class="font-weight-light">{{
                                    $get(getServiceInfosDetails, 'title', '')
                                }}</span>
                            </span>
                        </p>

                        <span
                            ><b>{{ `${$t('service-info:author')}` }}:</b>
                            {{
                                `${$get(
                                    getServiceInfosDetails.createdBy,
                                    'name',
                                    'Brak'
                                )} ${$get(
                                    getServiceInfosDetails.createdBy,
                                    'lastname',
                                    ''
                                )}`
                            }}</span
                        >

                        <span
                            v-if="
                                getServiceInfosDetails.responsible &&
                                getServiceInfosDetails.responsible.length > 0
                            "
                            ><b>{{ `${$t('service-info:responsible')}` }}:</b>
                            <span
                                v-for="(el,
                                index) in getServiceInfosDetails.responsible"
                                :key="el._id"
                            >
                                {{
                                    `${index > 0 ? ', ' : ''}${$get(
                                        el,
                                        'name',
                                        'Brak'
                                    )} ${$get(el, 'lastname', '')}`
                                }}
                            </span>
                        </span>
                        <span v-else
                            ><b>{{ `${$t('service-info:responsible')}` }}:</b>
                            <span>{{ ` ${$t('global:empty')}` }}</span>
                        </span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="6" xl="6" lg="8" md="12" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="9"
                        ><v-text-field
                            readonly
                            :value="$get(getServiceInfosDetails, 'title', '')"
                            :label="$t('service-info:informationTitle')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="3"
                        ><v-text-field
                            readonly
                            :value="`${
                                getServiceInfosDetails.archiveDate
                                    ? $moment(
                                          getServiceInfosDetails.archiveDate
                                      ).format('DD-MM-YYYY')
                                    : $t('global:empty')
                            }`"
                            :label="$t('service-info:archiveDate')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                        ><v-textarea
                            readonly
                            :value="$get(getServiceInfosDetails, 'info', '')"
                            :label="$t('service-info:info')"
                            no-resize
                            rows="1"
                            auto-grow
                        ></v-textarea>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea
                            readonly
                            :value="getInformationTo(getServiceInfosDetails)"
                            :label="$t('service-info:infoTo')"
                            no-resize
                            rows="1"
                            auto-grow
                        ></v-textarea>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-select
                            class="float-right pt-4"
                            style="width: 35%;"
                            readonly
                            append-icon=""
                            v-model="getServiceInfosDetails.priority"
                            :items="
                                getRegistryDictionaries.priorityType
                                    ? getRegistryDictionaries.priorityType
                                    : []
                            "
                            dense
                            :label="$t('service-info:priority')"
                            return-object
                            item-value="field"
                            ><template v-slot:item="data">
                                <span v-if="data.item.backgroundColor">
                                    <v-chip
                                        class="mx-2"
                                        :color="data.item.backgroundColor"
                                        small
                                        label
                                        :text-color="
                                            data.item.textColor
                                                ? data.item.textColor
                                                : '#4d4d4d'
                                        "
                                    >
                                        {{ data.item.field }}
                                    </v-chip>
                                </span>
                                <span class="truncate-list" v-else>
                                    {{ data.item.field }}
                                </span>
                            </template>
                            <template v-slot:selection="data">
                                <span v-if="data.item.backgroundColor">
                                    <v-chip
                                        class="mx-2"
                                        :color="data.item.backgroundColor"
                                        small
                                        label
                                        :text-color="
                                            data.item.textColor
                                                ? data.item.textColor
                                                : '#4d4d4d'
                                        "
                                    >
                                        {{ data.item.field }}
                                    </v-chip>
                                </span>
                                <span class="truncate-list" v-else>
                                    {{ data.item.field }}
                                </span>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    computed: {
        ...mapGetters(['getServiceInfosDetails', 'getRegistryDictionaries']),
    },
    methods: {
        getInformationTo(items) {
            const informations = [...items.to.users, ...items.to.departments]
            return informations
                ? informations.map(
                      (el, index) =>
                          `${index > 0 ? ' ' : ''}${this.$get(el, 'name', '')}${
                              el.lastname ? ' ' : ''
                          }${this.$get(el, 'lastname', '')}`
                  )
                : ''
        },
    },
}
</script>
