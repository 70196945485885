var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"details__card"},[_c('v-col',{staticClass:"pa-5",attrs:{"cols":"3","xl":"3","lg":"3","md":"5","sm":"12"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12"}},[_c('p',{staticClass:"font-weight-bold d-flex flex-column headline pa-0 ma-0 mb-5"},[_c('span',[_c('b',[_vm._v(_vm._s(`${_vm.$t('service-info:title')}`)+": ")]),_c('span',{staticClass:"font-weight-light"},[_vm._v(_vm._s(_vm.$get(_vm.getServiceInfosDetails, 'title', '')))])])]),_c('span',[_c('b',[_vm._v(_vm._s(`${_vm.$t('service-info:author')}`)+":")]),_vm._v(" "+_vm._s(`${_vm.$get( _vm.getServiceInfosDetails.createdBy, 'name', 'Brak' )} ${_vm.$get( _vm.getServiceInfosDetails.createdBy, 'lastname', '' )}`))]),(
                            _vm.getServiceInfosDetails.responsible &&
                            _vm.getServiceInfosDetails.responsible.length > 0
                        )?_c('span',[_c('b',[_vm._v(_vm._s(`${_vm.$t('service-info:responsible')}`)+":")]),_vm._l((_vm.getServiceInfosDetails.responsible),function(el,index){return _c('span',{key:el._id},[_vm._v(" "+_vm._s(`${index > 0 ? ', ' : ''}${_vm.$get( el, 'name', 'Brak' )} ${_vm.$get(el, 'lastname', '')}`)+" ")])})],2):_c('span',[_c('b',[_vm._v(_vm._s(`${_vm.$t('service-info:responsible')}`)+":")]),_c('span',[_vm._v(_vm._s(` ${_vm.$t('global:empty')}`))])])])],1)],1),_c('v-col',{staticClass:"pa-5",attrs:{"cols":"6","xl":"6","lg":"8","md":"12","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.$get(_vm.getServiceInfosDetails, 'title', ''),"label":_vm.$t('service-info:informationTitle')}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"readonly":"","value":`${
                            _vm.getServiceInfosDetails.archiveDate
                                ? _vm.$moment(
                                      _vm.getServiceInfosDetails.archiveDate
                                  ).format('DD-MM-YYYY')
                                : _vm.$t('global:empty')
                        }`,"label":_vm.$t('service-info:archiveDate')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"readonly":"","value":_vm.$get(_vm.getServiceInfosDetails, 'info', ''),"label":_vm.$t('service-info:info'),"no-resize":"","rows":"1","auto-grow":""}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"readonly":"","value":_vm.getInformationTo(_vm.getServiceInfosDetails),"label":_vm.$t('service-info:infoTo'),"no-resize":"","rows":"1","auto-grow":""}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{staticClass:"float-right pt-4",staticStyle:{"width":"35%"},attrs:{"readonly":"","append-icon":"","items":_vm.getRegistryDictionaries.priorityType
                                ? _vm.getRegistryDictionaries.priorityType
                                : [],"dense":"","label":_vm.$t('service-info:priority'),"return-object":"","item-value":"field"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(data.item.backgroundColor)?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item.backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                            ? data.item.textColor
                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(data.item.backgroundColor)?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item.backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                            ? data.item.textColor
                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(_vm.getServiceInfosDetails.priority),callback:function ($$v) {_vm.$set(_vm.getServiceInfosDetails, "priority", $$v)},expression:"getServiceInfosDetails.priority"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }