<template>
    <v-container>
        <Header
            :registryStatus="getServiceInfosStatuses"
            :recordIndex="getServiceInfosDetails.index"
            :registryTitle="getServiceInfosRegistry.name"
            :registryUrl="'service-info'"
            :actualStatus="getServiceInfosDetails.status"
            :avatarData="getServiceInfosDetails.createdBy"
            @status-changed="changeStatus"
        ></Header>
        <Main :items="items" class="p-relative">
            <v-tab-item
                v-for="item in items"
                :key="item.id"
                slot="tabItems"
                :id="item.id"
            >
                <component :is="item.component"></component>
            </v-tab-item>
            <div slot="information">
                <Information />
                <Modal
                    :title="$t('service-info:editInfo')"
                    :open="open"
                    :height="'640'"
                    v-on:close="closeModal()"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        @closeModal="open = false"
                        :action="'edit'"
                        slot="buttons"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--details">
                    <ButtonsInfo @openConfirmModal="openConfirmModal" />
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                dark
                                v-on="on"
                                v-if="edit()"
                                small
                                @click="editInfos()"
                                class="buttons--add margin--1 ml-4"
                            >
                                <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('service-info:edit') }}</span>
                    </v-tooltip>
                </div>
                <ConfirmModal
                    :openConfirm="openConfirm"
                    @close-confirm="closeConfirmModal"
                >
                    <div slot="main"><ConfirmMailContent /></div>
                    <div slot="buttons">
                        <div class="d-flex justify-center">
                            <v-btn
                                color="rgb(117, 117, 117)"
                                width="110"
                                height="40"
                                @click="closeConfirmModal()"
                                class="white--text mx-2"
                                >{{ $t('global:cancel') }}</v-btn
                            >
                            <v-btn
                                width="110"
                                height="40"
                                color="#445d75"
                                class="white--text mx-2"
                                @click="sendMessage()"
                                >{{ $t('global:send') }}</v-btn
                            >
                        </div>
                    </div>
                </ConfirmModal>
            </div>
        </Main>
    </v-container>
</template>

<script>
import store from './../../../store/index'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import Information from '../../../components/Registries/Service-info/Details/Information.vue'
import AEContent from './../../../components/Registries/Service-info/Modal/Content'
import Buttons from './../../../components/Registries/Service-info/Modal/Buttons'
import ButtonsInfo from '../../../components/Registries/Service-info/Details/Buttons.vue'
import ConfirmMailContent from '../../../components/Registries/Service-info/ConfirmModal/ConfirmMailModal.vue'
import Rest from '../../../plugins/Rest'

export default {
    i18nOptions: { namespaces: ['global'] },
    components: {
        Information,
        AEContent,
        Buttons,
        ButtonsInfo,
        ConfirmMailContent,
    },
    data() {
        return {
            open: false,
            openConfirm: false,
            items: [],
        }
    },
    computed: {
        ...mapGetters([
            'getServiceInfosStatuses',
            'getServiceInfosRegistry',
            'getServiceInfosDetails',
        ]),
    },
    methods: {
        ...mapMutations([
            'setServiceInfosDetails',
            'setServiceInfosStatuses',
            'setServiceInfosRegistry',
            'setServiceInfosModal',
            'clearServiceInfosErrors',
            'clearServiceInfosModal',
        ]),
        ...mapActions([
            'fetchServiceInfosRegistry',
            'fetchServiceInfos',
            'updateServiceInfosStatus',
            'sendInfoMail',
            'fetchAllDepartments',
            'fetchUsers',
        ]),
        changeStatus(status) {
            this.updateServiceInfosStatus({
                status,
                id: this.getServiceInfosDetails._id,
            })
        },
        edit() {
            return this.checkPermissions('UPDATE')
        },
        async editInfos() {
            await void this.fetchAllDepartments({ select: 'users' })
            await void this.fetchUsers({
                select: 'name lastname mail status ',
                where: {
                    isUser: true,
                    'status.state': 'ACTIVE',
                    isDeleted: false,
                },
            })
            this.open = true
            this.clearServiceInfosErrors()
            this.setServiceInfosModal()
        },
        closeModal() {
            this.open = false
            this.clearServiceInfosErrors()
            this.clearServiceInfosModal()
        },
        closeConfirmModal() {
            this.openConfirm = false
        },
        openConfirmModal() {
            this.openConfirm = true
        },
        async sendMessage() {
            this.openConfirm = false
            this.sendInfoMail()
        },
    },
    beforeRouteEnter(to, from, next) {
        store.dispatch('fetchServiceInfosRegistry')
        store.dispatch('fetchServiceInfos', { id: to.params.id, next })
    },
}
</script>
